/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  var bannerOffer = $('.banner-offer');
  var bannerTop = $('.banner-top-tablet');
  if (bannerTop.length > 0) {
    $('.banner-top-tablet__close-button').on('click', function () {
      bannerTop.addClass('closed');
      $('header.banner').css('top', '0');
    });
  }
  if (bannerOffer.length > 0) {
    $(window).scroll(function () {
      if ($('.closed').length === 0) {
        var scrollTop = $(window).scrollTop();
        if (scrollTop >= 350) {
          bannerOffer.addClass('scroll');
        } else {
          bannerOffer.removeClass('scroll');
        }
      }
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        $(window).load(function () {
          $(window).trigger('resize');
          $('body').css('opacity', '1');
        });

        $(document).ready(function () {

          // Add smooth scrolling to all links
          // $("a[href^='#']").on('click', function(event) {
          //   //event.preventDefault();
          //   // Make sure this.hash has a value before overriding default behavior
          //   if (this.hash !=="" && this.pathname == window.location.pathname && this.protocol == window.location.protocol && this.host == window.location.host) {
          //     // Prevent default anchor click behavior
          //     event.preventDefault();
          //
          //     // Store hash
          //     var hash = this.hash;
          //
          //     // Using jQuery's animate() method to add smooth page scroll
          //     // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          //     $('html, body').animate({
          //       scrollTop: $(hash).offset().top
          //     }, 800, function(){
          //
          //       // Add hash (#) to URL when done scrolling (default click behavior)
          //       window.location.hash = hash;
          //     });
          //   } // End if
          // });
          //Mobile Menu Stuff
          jQuery('#mobile-menu-open, #mobile-menu-close').click(function () {
            jQuery('#mobile-menu-container').slideToggle("slow");
            jQuery('#mobile-menu-container').toggleClass('active');
            jQuery('#mobile-nav-icons').toggleClass('active');
          });
          jQuery('.menu-item-has-children').click(function () {
            // console.log('open-child');
            whichOpen = jQuery(this);
            whichOpen.toggleClass('active-open');
          });
          //Search Button Display Search Modal
          $('a.search').click(function () {
            $('#search-modal').toggleClass('active');
            $('#search-modal').toggleClass('front');
            $('#search-modal input').attr('value', '');
            $('#search-modal input').focus();
            $('#search-modal input').select();
          });
          $('img.search').click(function () {
            $('#search-modal').toggleClass('active');
            $('#search-modal').toggleClass('front');
            $('#search-modal input').attr('value', '');
            $('#search-modal input').focus();
            $('#search-modal input').select();
          });
          $('#search-close').click(function () {
            $('#search-modal').toggleClass('active');
            setTimeout(function () {
              $('#search-modal').toggleClass('front');
            }, 500);
          });
        });

        $(window).scroll(function (event) {
          // console.log('scrolled');
          var scroll = $(window).scrollTop();

          if (scroll > 38) {
            $('header').addClass('scroll');
            $('#header-main').addClass('fix-here');
          } else {
            $('header').removeClass('scroll');
            $('#header-main').removeClass('fix-here');
          }
          // console.log(scroll);

        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
